import { UIContext } from '@alphafounders/ui';
import { useNewRelic } from '@careos/newrelic';
import DateFnsUtils from '@date-io/date-fns';
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useAsyncEffect } from 'ahooks';
import i18n from 'i18next';
import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Helmet from 'react-helmet';
import { ThemeProvider } from 'styled-components';

import CallAudio from 'presentation/components/CallButtonV2/CallAudio';
import AudioTrack from 'presentation/components/LeadDetails/AudioTrack';
import SnackbarComponent from 'presentation/components/SnackBar';
import { GlobalError } from 'presentation/pages/admin/auth/Page500';
import {
  useAppDispatch,
  useAppSelector,
} from 'presentation/redux/hooks/typedHooks';

import { initApplication } from './presentation/redux/actions/general/appInitiation';
import Routes from './presentation/routes/Routes';
import maTheme from './presentation/theme';
import {
  changeLanguage,
  getLanguage,
  setLanguageToStorage,
  initialLanguage,
} from './presentation/theme/localization';

function App() {
  const dispatch = useAppDispatch();
  const theme = useAppSelector((state) => state.themeReducer);

  useEffect(() => {
    dispatch(initApplication());
  }, [dispatch]);

  useAsyncEffect(async () => {
    initialLanguage();
    setLanguageToStorage();
    await changeLanguage(getLanguage());
  }, []);

  const { nrAgent } = useNewRelic();

  const logError:
    | ((error: Error, info: React.ErrorInfo) => void)
    | undefined = (error) => {
    // Do something with the error, e.g. log to an external API
    // Build chain of error reporting function to filter out unwanted errors
    nrAgent?.noticeError(error);
  };

  return (
    <>
      <Helmet titleTemplate="%s" defaultTitle="Rabbit Care" />
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
            <ThemeProvider theme={maTheme[theme.currentTheme]}>
              <ErrorBoundary fallbackRender={GlobalError} onError={logError}>
                <UIContext i18nInstance={i18n as any}>
                  <SnackbarComponent />
                  <Routes />
                  <AudioTrack />
                  <CallAudio />
                </UIContext>
              </ErrorBoundary>
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </>
  );
}

export default App;
