import withCircle from 'presentation/HOCs/WithCircle';
import { IRoutes } from './route.interface';
import { getUserPermission } from './helper';
import { lazy } from 'react';
import {
  AdminUser,
  DocumentSearchIcon,
  LeadAll,
  PackageImportIcon,
} from 'presentation/components/icons';
import {
  CRM_ROUTES,
  getPackageComparisonlUrl,
  getPackageDetailUrl,
  getPackagesUrl,
} from './Urls';

const DashboardLayout = lazy(() => import('../layouts/Dashboard'));
const PageLayout = lazy(() => import('../layouts/Page'));

// lead pages
const LeadAllPage = lazy(
  () =>
    import('presentation/pages/health-insurance/leads/LeadAllPage/AllLeadsPage')
);
const LeadImportPage = lazy(
  () => import('presentation/pages/health-insurance/leads/ImportLeadPage')
);
const LeadRejectPage = lazy(
  () => import('presentation/pages/health-insurance/leads/LeadRejectionPage')
);

// Order mass status update page
const OrderMassStatusUpdate = lazy(
  () => import('../pages/health-insurance/orders/ImportMassStatusChange')
);

const OrdersAllPage = lazy(
  () => import('../pages/health-insurance/orders/All')
);
const OrderDocumentsPage = lazy(
  () => import('../pages/health-insurance/orders/Documents')
);
const QCModulePage = lazy(
  () => import('../pages/health-insurance/orders/QCModule')
);
const OrderSubmissionPage = lazy(
  () => import('../pages/health-insurance/orders/Submission')
);
const OrderApprovalPage = lazy(
  () => import('../pages/health-insurance/orders/Approval')
);
const PrintingAndShipping = lazy(
  () => import('../pages/health-insurance/orders/PrintingAndShipping')
);
const ExportShipment = lazy(
  () => import('../pages/health-insurance/orders/ExportShipment')
);
const PackageSearchPage = lazy(
  () => import('../pages/health-insurance/package/packageSearch')
);
const PackageImportPage = lazy(
  () => import('../pages/health-insurance/package/ImportPackagePage')
);
// Discounts Page
const DiscountImportPage = lazy(
  () => import('../pages/health-insurance/Discounts/DiscountsImport')
);
const DiscountApprovalPage = lazy(
  () => import('../pages/health-insurance/Discounts/DiscountApprovalPage')
);
const DiscountVoucherPage = lazy(
  () => import('../pages/health-insurance/Discounts/Voucher')
);
const DiscountCampaignPage = lazy(
  () => import('../pages/health-insurance/Discounts/CampaignPage')
);
const ContractListingPage = lazy(
  () => import('../pages/health-insurance/CarePay/Contracts')
);
const TransactionListingPage = lazy(
  () => import('../pages/health-insurance/CarePay/Transaction')
);

const PackageListingPage = lazy(
  () => import('../pages/health-insurance/leads/PackageListingPage')
);
const CreatePaymentPage = lazy(
  () => import('../pages/car-insurance/CreatePaymentPage')
);
const CreateContractPage = lazy(
  () => import('../pages/car-insurance/CreateContractPage')
);
const CustomQuotePage = lazy(
  () => import('../pages/car-insurance/LeadDetailsPage/CustomQuote')
);
const PackageDetailPage = lazy(
  () => import('presentation/pages/health-insurance/leads/PackageDetailPage')
);
const PackageComparisonPage = lazy(
  () => import('presentation/pages/car-insurance/PackageComparisonPage')
);
const LeadDetailPage = lazy(
  () => import('presentation/pages/health-insurance/leads/leadDetailsPage')
);

const PhoneBook = lazy(
  () => import('presentation/pages/car-insurance/InsurerPhoneBook')
);

const UserPageV2 = lazy(() => import('presentation/pages/admin/users/UserV2'));
const AdminTeamPage = lazy(
  () => import('presentation/pages/admin/Team/newTeam')
);

export const healthRoutes: IRoutes = {
  id: 'Leads',
  path: '/health/leads',
  name: 'menu.lead.root',
  layout: PageLayout,
  permission: getUserPermission('/health/leads'),
  children: [
    {
      id: 'leadsAll',
      path: '/health/leads',
      name: 'menu.lead.root',
      component: LeadAllPage,
      permission: getUserPermission('/health/leads'),
      showInHeader: true,
      hiddenIcon: true,
    },
    {
      id: 'leadsDetails',
      path: '/health/leads/:id',
      name: 'Lead',
      component: LeadDetailPage,
      permission: getUserPermission('/health/leads/:id'),
    },
    {
      path: '/health/leads/:id/create-payment',
      name: 'Create Payment',
      component: CreatePaymentPage,
      permission: getUserPermission('/health/leads/:id/create-payment'),
    },
    {
      path: '/health/leads/:id/create-contract',
      name: 'Create Contract',
      component: CreateContractPage,
      permission: getUserPermission('/health/leads/:id/create-contract'),
    },
    {
      path: '/health/leads/:id/custom-quote',
      name: 'Custom Quote Page',
      component: CustomQuotePage,
      permission: getUserPermission('/health/leads/:id/custom-quote'),
    },
    {
      path: getPackagesUrl(undefined, true),
      name: 'Packages',
      component: PackageListingPage,
      permission: getUserPermission('/health/leads/:id/packages'),
    },
    {
      path: `${getPackageDetailUrl({ isHealth: true })}`,
      name: 'Package Detail',
      component: PackageDetailPage,
      permission: getUserPermission('/health/leads/:id/detail'),
    },
    {
      path: `${getPackageComparisonlUrl({ isHealth: true })}`,
      name: 'Package Comparison',
      component: PackageComparisonPage,
      permission: getUserPermission('/health/leads/:id/compare'),
    },
    {
      path: '/insurer-phonebook',
      name: 'Phone Book',
      component: PhoneBook,
      permission: getUserPermission('/insurer-phonebook'),
    },
    {
      id: 'importLead',
      path: '/health/leads/import',
      name: 'menu.autoAssignment.leadsImport',
      component: LeadImportPage,
      permission: getUserPermission('/health/leads/import'),
    },
    {
      id: 'rejectLead',
      path: '/health/leads/rejection',
      name: 'menu.lead.rejections',
      component: LeadRejectPage,
      permission: getUserPermission('/health/leads/rejection'),
    },
    {
      id: 'transactions',
      path: '/health/transactions',
      name: 'menu.transactions',
      component: TransactionListingPage,
      permission: getUserPermission('/health/transactions'),
      showInHeader: true,
      hiddenIcon: true,
    },
    {
      id: 'orders',
      path: '/health/orders',
      name: 'menu.orders',
      component: OrdersAllPage,
      permission: getUserPermission('/health/orders'),
      showInHeader: true,
      hiddenIcon: true,
    },
    {
      path: '/health/leads/package-listing',
      name: 'menu.lead.packageListing',
      component: PackageListingPage,
      sideBar: false,
      permission: getUserPermission('/health/leads/package-listing'),
    },
    {
      id: 'users',
      path: '/health/users',
      name: 'menu.userManagement.users',
      component: UserPageV2,
      sideBar: true,
      permission: getUserPermission('/health/users'),
    },
    {
      id: 'teams',
      path: '/health/teams',
      name: 'menu.userManagement.teams',
      component: AdminTeamPage,
      sideBar: true,
      permission: getUserPermission('/health/teams'),
    },
    {
      id: 'campaign-discount',
      path: '/health/discounts/campaign',
      name: 'text.campaign',
      component: DiscountCampaignPage,
      showInHeader: true,
      hiddenIcon: true,
      permission: getUserPermission('/health/discounts/campaign'),
    },
    {
      id: 'contract-listing',
      path: '/health/contracts',
      name: 'menu.carePay.contractManagement',
      component: ContractListingPage,
      showInHeader: true,
      hiddenIcon: true,
      permission: getUserPermission('/health/contracts'),
    },
  ],
};

export const healthOrdersRoutes: IRoutes = {
  id: 'Orders',
  path: '/health/orders',
  name: 'menu.order.root',
  icon: withCircle(LeadAll),
  layout: DashboardLayout,
  sideBar: true,
  permission: getUserPermission('/orders'),
  children: [
    {
      id: 'ordersAll',
      path: '/health/orders/all',
      name: 'menu.order.all',
      component: OrdersAllPage,
      sideBar: true,
      permission: getUserPermission('/orders/all'),
    },
    {
      id: 'ordersDocuments',
      path: '/health/orders/documents',
      name: 'menu.order.documents',
      component: OrderDocumentsPage,
      sideBar: true,
      permission: getUserPermission('/orders/documents'),
    },
    {
      id: 'ordersQC',
      path: '/health/orders/qc',
      name: 'menu.order.QC',
      component: QCModulePage,
      sideBar: true,
      permission: getUserPermission('/orders/qc'),
    },
    {
      id: 'ordersSubmission',
      path: '/health/orders/submission',
      name: 'menu.order.submission',
      component: OrderSubmissionPage,
      sideBar: true,
      permission: getUserPermission('/orders/submission'),
    },
    {
      id: 'ordersApproval',
      path: '/health/orders/approval',
      name: 'menu.order.approval',
      component: OrderApprovalPage,
      sideBar: true,
      permission: getUserPermission('/orders/approval'),
    },
    {
      id: 'ordersPrintingAndShipping',
      path: '/health/orders/shipment',
      name: 'menu.order.printingShipping',
      component: PrintingAndShipping,
      sideBar: true,
      permission: getUserPermission('/orders/shipment'),
    },
    {
      id: 'ordersExportShipment',
      path: '/health/orders/export-shipment',
      name: 'text.exportShipmentMenu',
      component: ExportShipment,
      sideBar: true,
      permission: getUserPermission('/orders/export-shipment'),
    },
    {
      id: 'ordersMassStatusUpdate',
      path: '/health/orders/mass-status-update',
      name: 'order.massAssign.massStatusChange',
      component: OrderMassStatusUpdate,
      sideBar: true,
      permission: getUserPermission('/orders/mass-status-update'),
    },
  ],
};

export const healthPackageRoutes: IRoutes = {
  id: 'Package',
  path: '/health/package',
  name: 'menu.package.root',
  icon: withCircle(PackageImportIcon),
  layout: DashboardLayout,
  sideBar: true,
  permission: getUserPermission('/package'),
  children: [
    {
      id: 'packageSearch',
      path: '/health/package/search',
      name: 'menu.package.search',
      component: PackageSearchPage,
      sideBar: true,
      permission: getUserPermission('/package/search'),
    },
    {
      id: 'packageImport',
      path: '/health/package/import',
      name: 'menu.package.import',
      component: PackageImportPage,
      sideBar: true,
      permission: getUserPermission('/package/import'),
    },
  ],
};

export const healthDiscountRoutes: IRoutes = {
  id: 'Discounts',
  path: '/health/discounts',
  name: 'menu.discounts.root',
  icon: withCircle(AdminUser),
  layout: DashboardLayout,
  sideBar: true,
  permission: getUserPermission('/discounts'),
  children: [
    {
      id: 'discountsApproval',
      path: '/health/discounts/approval',
      name: 'menu.discounts.approval',
      component: DiscountApprovalPage,
      sideBar: true,
      permission: getUserPermission('/discounts/approval'),
    },
    {
      id: 'discountsImport',
      path: '/health/discounts/import',
      name: 'menu.discounts.importDiscount',
      component: DiscountImportPage,
      sideBar: true,
      permission: getUserPermission('/discounts/import'),
    },
    {
      id: 'discountsVoucher',
      path: CRM_ROUTES.DISCOUNT_VOUCHER,
      name: 'menu.discounts.voucher',
      component: DiscountVoucherPage,
      sideBar: true,
      permission: getUserPermission(CRM_ROUTES.DISCOUNT_VOUCHER),
    },
    {
      id: 'discountsCampaign',
      path: '/health/discounts/campaign',
      name: 'text.campaign',
      component: DiscountCampaignPage,
      sideBar: true,
      permission: getUserPermission('/discounts/campaign'),
    },
  ],
};

export const healthCarePayRoutes: IRoutes = {
  id: 'CarePayListing',
  path: '/health/care-pay',
  name: 'menu.carePay.root',
  icon: withCircle(DocumentSearchIcon),
  layout: DashboardLayout,
  sideBar: true,
  permission: getUserPermission('/care-pay'),
  children: [
    {
      id: 'TransactionListing',
      path: '/health/care-pay/transactions',
      name: 'menu.carePay.transactionListing',
      component: TransactionListingPage,
      sideBar: true,
      permission: getUserPermission('/care-pay/transactions'),
    },
  ],
};
